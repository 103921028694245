<template>
  <v-container fluid>
    <!-- editor -->
    <editor
      :currencies="currencies"
      :initial="dataItem"
      @data="save"
      :pageMode="pageMode"
    ></editor>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    currencies: [],
    pageMode: "view",
  }),
  watch: {
    "$route.params.id": {
      handler: "getBpMaster",
      immediate: true,
    },
  },
  methods: {
    getBpMaster(val) {
      const self = this;
      this.$store.commit("loader", true);
      this.$store
        .dispatch("get", `/bp_masterdata/${val}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
          this.$store.commit("loader", false);
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;

      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      data.ListNum = 1;
      const url = `/bp_masterdata/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$router.push("/business-partners/item-master-data");
          }
          self.$refs.snackbar.show("Item group created", "green");
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>